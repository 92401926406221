<div class="search-results"
    infinite-scroll
    (scrolled)="onScrollDown($event)">

    <ng-template #artBox></ng-template>
<!--      <div class="list-group">-->
<!--        <a class="list-group-item list-group-item-action" *ngFor="let i of listArray">-->
<!--          <p class="mb-1">{{i}} Item added on {{direction}}</p>-->
<!--        </a>-->
<!--      </div>-->

</div>

