import { Component, OnInit } from '@angular/core';
import {YEARS} from '../utils/collection_contents';

@Component({
  selector: 'app-about-contact',
  templateUrl: './about-contact.component.html',
  styleUrls: ['./about-contact.component.css']
})
export class AboutContactComponent implements OnInit {
  years = Object.keys(YEARS);

  constructor() { }

  ngOnInit(): void {
    console.log(this.years);
  }

}
