import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtensionType, stringToExtensionType } from '../utils/utils';


@Component({
  selector: 'app-collection-footer',
  templateUrl: './collection-footer.component.html',
  styleUrls: ['./art-day-pieces.component.css']
})
export class CollectionFooterComponent implements OnInit {
  @Input() yearStr = '0';
  @Input() rawTitle = 't';
  @Input() publisher = '';
  @Input() fourth = '';

  year = Number(this.yearStr);
  home = false;
  drawFooterLine = true;
  title = '';

  constructor(private route: ActivatedRoute, private router: Router) {
    const url = this.router.url;
    this.home = url === '/' || url === '';
  }

  ngOnInit(): void {
    this.yearStr = this.yearStr.replace(/-/g, ' '); // Use global flag to replace all occurrences
    this.year = Number(this.yearStr);
    this.title = this.rawTitle.replace(/-/g, ' ');
  }
}
