<div class='footer' width=100%>

<div class='left'>{{title}}</div>
<div class='left'>{{yearStr}}</div>
<div class='left'>{{publisher}}</div>
<div class='left'>{{fourth}}</div>


</div>
<hr *ngIf="drawFooterLine"/>
