import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ExtensionType} from '../utils/utils';

@Component({
  selector: 'app-art-day-wrapper',
  templateUrl: './art-day-wrapper.component.html',
  styleUrls: ['./art-day-wrapper.component.css']
})

export class ArtDayWrapperComponent implements OnInit {
  year = '0';
  filePath = '';
  title = '';
  publisher = '';
  fourth = '';
  ext = '';
  myhtml = '';

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
  }
}
