import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ExtensionType, stringToExtensionType} from '../utils/utils';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-art-day-body',
  templateUrl: './art-day-body.component.html',
  styleUrls: ['./art-day-pieces.component.css']
})

export class ArtDayBodyComponent implements OnInit {
  @Input() yearStr = '0';
  @Input() filePath = 'f';
  @Input() extStr = 'e';
  @Input() myhtml = 'ef';


  addLineBreaks = false;
  html: SafeHtml = '';
  thumbnailPath = '';

  year = Number(this.yearStr);
  fileName = '';
  ExtensionType = ExtensionType;
  ext = ExtensionType.Other;

  isWide = true;
  currentOverlay = '';
  fileListError = false;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.fileName = this.filePath.split('/')[this.filePath.split('/').length - 1];
    this.year = Number(this.yearStr);
    console.log(this.extStr)
    this.ext = stringToExtensionType(this.extStr);
    if (this.ext === ExtensionType.Pdf) {
      const lastDotIndex: number = this.fileName.lastIndexOf('.');
      this.thumbnailPath = 'assets/Thumbnails/' + this.fileName.substring(0, lastDotIndex) + '.jpg';
    }
  }

  overlayOn(): void {
    this.currentOverlay = String(this.fileName) + '-overlay';
    this.setSmartOverlayScale();
    (document.getElementById(this.currentOverlay) as HTMLElement).style.display = 'block';
  }

  overlayOff(): void {
    (document.getElementById(this.currentOverlay) as HTMLElement).style.display = 'none';
  }

  onWheel(event: WheelEvent): void {
    let multiplier = 1;
    if (this.isWide) {
      if (event.deltaMode === event.DOM_DELTA_LINE) {
        multiplier = 40;
      }
      //  else if (event.deltaMode == event.DOM_DELTA_LINE) {
      //   multiplier = 400;
      // }
      (document.getElementById(this.currentOverlay) as HTMLElement).scrollBy( event.deltaY * multiplier, 0 );
      event.preventDefault();
    } else {
      if (event.deltaMode === event.DOM_DELTA_LINE) {
        multiplier = 40;
      }
      //  else if (event.deltaMode == event.DOM_DELTA_LINE) {
      //   multiplier = 400;
      // }
      (document.getElementById(this.currentOverlay) as HTMLElement).scrollBy( 0, event.deltaY * multiplier );
      event.preventDefault();
    }
  }

  setSmartOverlayScale(): void {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const ratio = screenWidth / screenHeight;
    const img = (document.getElementById(this.currentOverlay) as HTMLElement).childNodes[0] as HTMLImageElement;
    const r = img.width / img.height;

    if (r < ratio) {
      this.isWide = false;
      img.setAttribute('height', 'auto');
      img.setAttribute('width', '100%');
    } else {
      this.isWide = true;
      img.setAttribute('height', '100%');
      img.setAttribute('width', 'auto');
    }
  }
}
