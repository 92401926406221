import { Component, ComponentFactoryResolver, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {loadCollection, addToCollection, loadProject, loadYearsCollection} from '../utils/utils';
import {PROJECTS} from '../utils/collection_contents';

@Component({
  selector: 'app-image-tower',
  templateUrl: './image-tower.component.html',
  styleUrls: ['./image-tower.component.css']
})
export class ImageTowerComponent implements OnInit {
  // @ViewChildren('artBox', { read: ViewContainerRef }) artBoxes!: QueryList<ViewContainerRef>;
  @ViewChild('artBox', { static: true, read: ViewContainerRef }) artBox!: ViewContainerRef;
  numItems = 2;
  collectionName = '';
  isProjectPage = false;

  constructor(private route: ActivatedRoute, private router: Router, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {


    this.route.paramMap.subscribe(params => {
      this.numItems = 2;
      this.collectionName = String(params.get('collection'));
      console.log(this.collectionName);
      if (PROJECTS.hasOwnProperty(this.collectionName)){
        this.isProjectPage = true;
      }

      this.route.url.subscribe(urlSegments => {
        if (urlSegments.length > 0) {
          const firstSegment = urlSegments[0].path;
          if (firstSegment === 'ArtistsBooks') {
            loadProject(this.artBox, 'ArtistsBooks', this.collectionName, this.componentFactoryResolver);
          }
          else if (firstSegment === 'Years'){
            this.numItems = 1000000;
            loadYearsCollection(this.artBox, this.collectionName, this.componentFactoryResolver, this.numItems);
          }
          else {
            loadCollection(this.artBox, this.collectionName, this.componentFactoryResolver, this.numItems, this.isProjectPage);
          }
        }
      });
    });
  }

  onScrollDown(ev: any): void {
    this.numItems += 2;
    if (!this.isProjectPage) {
      addToCollection(this.artBox, this.collectionName, this.componentFactoryResolver, this.numItems, this.isProjectPage);
    }
  }

  overlayOn(id: number): void {
    (document.getElementById(String(id) + '-overlay') as HTMLElement).style.display = 'block';
  }

  overlayOff(id: number): void {
    (document.getElementById(String(id) + '-overlay') as HTMLElement).style.display = 'none';
  }

  onWheel(event: WheelEvent, id: number): void {
    (document.getElementById(String(id) + '-overlay') as HTMLElement).scrollLeft += event.deltaY * 50;
    event.preventDefault();
  }
}
