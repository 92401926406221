<body>
 <div style="position: relative; min-height: 100vh" id="page-container">
   <div style="padding-bottom: 2.5rem" id="content-wrap">
     <div class='body-wrap'>
      <app-header></app-header>
      <!-- The routed views render in the <router-outlet>-->
      <router-outlet></router-outlet>
     </div>
      <app-footer></app-footer>
   </div>
 </div>
</body>
