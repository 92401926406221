<!-- ======= Icons used for dropdown (you can use your own) ======== -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css">

<div id='header'>
  <div id='button-div'>
    <img id='sidenav-button' (click)="openNav()" src="/assets/icons/hamburger.png" alt="hamburger-icon"/>
  </div>
  <div id='logo'>
    <a routerLink="/"><img src='/assets/icons/logo.png' style="width:18vw;height: auto;" alt="logo icon"/></a>
  </div>
</div>

<div id=navigation>
  <ul class="topnav">


    <li dropdown [autoClose]="false" container="body">
      <a id="button-nested" dropdownToggle class="dropdown-toggle" aria-controls="dropdown-nested">
        Stories<span class="caret"></span>
      </a>
      <ul id="dropdown-nested" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-nested">
        <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body"
            *ngFor="let item of StoriesNames">
          <a class="dropdown-item" href="Stories/{{item}}">{{item.split('-').join(' ').split('_').slice(1, 2).join('_')}}</a>
        </li>
        <li role="menuitem" dropdown triggers="onclick" placement="right" container="body">
          <a dropdownToggle class="dropdown-item dropdown-toggle"
             (click)="false">More<span class="caret"></span></a>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body"
                *ngFor="let item of MoreStoriesNames">
              <a class="dropdown-item" href="Stories/More/{{item}}">{{item.split('-').join(' ').split('_').slice(1, 2).join('_')}}</a></li>
          </ul>
        </li>
      </ul>
    </li>


    <li dropdown [autoClose]="false" container="body">
      <a id="ArtBooks-button-nested" dropdownToggle class="dropdown-toggle" aria-controls="ArtBooks-dropdown-nested">
        Artists' Books<span class="caret"></span>
      </a>
      <ul id="ArtBooks-dropdown-nested" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="ArtBooks-button-nested">
        <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body"
            *ngFor="let item of ArtistsBkNames">
          <a class="dropdown-item" href="ArtistsBooks/{{item}}">{{item.split('-').join(' ').split('_').slice(1, 2).join('_')}}</a>
        </li>
      </ul>
    </li>

    <li>
      <a routerLink="/DrawingsAndPaintings" routerLinkActive="active">Drawings and Paintings</a>
    </li>

    <li>
      <a routerLink="/about-contact" routerLinkActive="active">About Michaela</a>
    </li>
<!--    <li>-->
<!--      <a routerLink="/links-page" routerLinkActive="active">Links</a>-->
<!--    </li>-->
  </ul>
</div>


<div id="mySidenav" class="sidenav">
  <div class='close-div'>
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  </div>
  <accordion [isAnimated]="true">
    <a>
      <!-- STORIES TAB    -->
      <accordion-group heading="Stories">
        <li *ngFor="let item of StoriesNames">
          <a href="Stories/{{item}}">{{item.split('-').join(' ').split('_').slice(1, 2).join('_')}}</a></li>
        <accordion [isAnimated]="true">
          <accordion-group heading="More">
            <li *ngFor="let item of MoreStoriesNames">
              <a href="Stories/More/{{item}}">{{item}}.split('_').slice(1, 2).join('_')</a></li>
          </accordion-group>
        </accordion>
      </accordion-group>
    </a>

    <!-- ARTISTS BOOKS TAB    -->
    <accordion [isAnimated]="true">
      <a>
        <accordion-group heading="Artists' Books">
            <li *ngFor="let item of ArtistsBkNames">
              <a href="ArtistsBooks/{{item}}">{{item.split('-').join(' ').split('_').slice(1, 2).join('_')}}</a></li>
        </accordion-group>
      </a>
      <a href="/about-contact">
        <accordion-group class="accordion-head" heading="About Michaela"></accordion-group>
      </a>
<!--      <a href="/links-page">-->
<!--        <accordion-group class="accordion-head" heading="Links"></accordion-group>-->
<!--      </a>-->
    </accordion>


  </accordion>
</div>

