<div class='some-page-wrapper'>
  <h1>2023</h1>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/Nashville_Review_icon.png" style='display:block; margin: 0 auto;' alt="Nashville Review Logo" width="250"/>
    </div>
    <div class='column'>
      <li> <a href="https://wp0.vanderbilt.edu/nashvillereview/archives/18541">LAB RAT 3 at Nashville Review</a> </li>
    </div>
  </div>

  <h1>2022</h1>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/the_rumpus.png" style='display:block; margin: 0 auto;' alt="rumpus logo"/>
    </div>
    <div class='column'>
       <li> <a href="https://therumpus.net/2022/12/15/lab-rat/">LAB RAT 2 at The Rumpus</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/the_offing.png" style='display:block; margin: 0 auto;' alt="offing logo"/>
    </div>
    <div class='column'>
        <li> <a href="https://theoffingmag.com/art/comics/lab-rat/">LAB RAT 1 at The Offing</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/ama.png" style='display:block; margin: 0 auto;' alt="ama logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://journalofethics.ama-assn.org/article/how-well-do-we-see-white-supremacy-source-harm-culture-medicine/2022-08">Complicit</a> </li>
      <li> <a href="https://journalofethics.ama-assn.org/article/war-bodies-limits/2022-08">Wounded</a> </li>
      <li> <a href="https://journalofethics.ama-assn.org/article/contraception-options/2022-08">Contraception Options</a> </li>
      <li> <a href="https://journalofethics.ama-assn.org/article/questioning-marriage-and-family-norms/2022-10">Newlywed</a> </li>
      <li> <a href="https://journalofethics.ama-assn.org/article/public-health-risk-and-civic-peership/2022-08">Juries During Covid-19</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/south_side_weekly.png" style='display:block; margin: 0 auto;' alt="southside weekly logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://southsideweekly.com/best-place-to-picnic-and-be-married-%ef%bf%bcping-tom-memorial-park/">Best Place to Picnic and Be Married</a> </li>
    </div>
  </div>


  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/graphic_medicine.png" style='display:block; margin: 0 auto;' alt="graphic medicine logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://www.graphicmedicine.org/wp-content/uploads/2022/07/Chicago-Schedule-7-14-with-remotes-1.pdf">Presenter at the Graphic Medicine Conference</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/f_news.png" style='display:block; margin: 0 auto;' alt="f_news magazine logo"/>
    </div>
    <div class='column'>

      <li> <a href="https://fnewsmagazine.com/2022/11/on-mobile/">On Mobile</a> <a href="https://fnewsmagazine.com/2022/07/part-time-faculty-organize-to-join-aicwu/">Part-Time Faculty Organize to Join AICWU</a></li>
      <li> <a href="https://fnewsmagazine.com/2022/06/the-cost-of-carbon-neutrality/">The Cost of Carbon Neutrality</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/06/danielle-tyler-invited-conversation/">Danielle Tyler Invited Conversation</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/05/marshland-roost/">Marshland Roost</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/05/the-mascot-may-1-2022/">The Mascot: May 1, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/04/google-cloud-storage-qa/">Google Cloud Storage</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/04/the-mascot-april-17-2022/">The Mascot: April 17, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/04/alumni-spotlight-kushala-vora/">Alumni Spotlight: Kushala Vora</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/04/do-you-vacuum/">Do you vacuum…</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/04/the-mascot-april-4-2022/">The Mascot: April 10, 2022</a></li>
      <li> <a href="https://fnewsmagazine.com/2022/04/faculty-spotlight-vanessa-viruet/">Faculty Spotlight: Vanessa Viruet</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/the-mascot-march-27-2022/">The Mascot: March 27, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/alumni-spotlight-nicole-seisler/">Alumni Spotlight: Nicole Seisler</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/cloudy-promises/">Cloudy Promises</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/the-mascot-march-20-2022/">The Mascot: March 20, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/germany-canceled-nuclear-energy/">Germany Canceled Nuclear Energy</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/the-mascot-march-13-2022/">The Mascot: March 13, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/book-review-the-secret-listener/">Book Review: “The Secret Listener”</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/03/the-mascot-march-6-2022/">The Mascot: March 6, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/02/visiting-artist-program-trenton-doyle-hancock/">Visiting Artist Program: Trenton Doyle Hancock</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/02/the-mascot-february-20-2022/">The Mascot: February 20, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/02/the-mascot-february-13-2022/">The Mascot: February 13, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/02/alumni-spotlight-emil-ferris/">Alumni Spotlight: Emil Ferris</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/02/the-mascot-february-6-2022/">The Mascot: February 6, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/01/student-spotlight-jonas-sun/">Student Spotlight: Jonas Sun</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/01/the-mascot-jan-30-2022/">The Mascot: Jan. 30, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/01/student-spotlight-paige-naylor/">Student Spotlight: Paige Naylor</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/01/news-in-brief-january-23-2022/">News in Brief: January 23, 2022</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/01/student-spotlight-jinwoo-hwon-lee-%ec%9d%b4%ed%9b%a4/">Student Spotlight: Jinwoo Hwon Lee &#51060;&#55012;</a> </li>
      <li> <a href="https://fnewsmagazine.com/2022/01/news-in-brief-january-9-2022/">News in Brief: January 9, 2022</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/asbmr.png" style='display:block; margin: 0 auto;' alt="asbmr logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://asbmr.onlinelibrary.wiley.com/doi/full/10.1002/jbm4.10691">Effect of Osteoblast-Specific Deletion of the Proton Receptor OGR1</a> </li>
    </div>
  </div>

  <h1>2021</h1>
  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/decentraland.PNG" style='display:block; margin: 0 auto;' alt="south side "/>
    </div>
    <div class='column'>
      <li> <a href="http://ar3t.gallery/">Ar3t.gallery, a web3 gallery in Decentraland made by Kevin Heberle</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/f_news.png" style='display:block; margin: 0 auto;' alt="f news logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://fnewsmagazine.com/2021/12/alumni-spotlight-ethan-persoff/">Alumni Spotlight: Ethan Persoff</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/12/news-in-brief-december-19-2021/">News in Brief: December 19, 2021</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/12/choose-your-charity/">Choose Your Charity</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/12/alumni-spotlight-m_m-m/">Alumni Spotlight: M_m&lt;M</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/12/celebrating-thirty-years-of-lumpen-a-hub-of-chicago-counterculture/">Celebrating Thirty Years of Lumpen, a Hub of Chicago Counterculture</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/11/behind-the-curtain-at-grad-open-studios-night/">Behind the Curtain at Grad. Open Studios Night</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/12/news-in-brief-december-5-2021/">News in Brief: December 5, 2021</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/11/pine-tree-shortage/">Pine Tree Shortage</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/11/rethink-refuse-and-reuse/">Rethink, Refuse, and Reuse</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/10/their-flood/">Their Flood</a> </li>
      <li> <a href="https://fnewsmagazine.com/2021/09/to-future-friend/">To: Future Friend</a> </li>

    </div>
  </div>

  <div class='row'>
    <div class='column'>
        <img src="/assets/icons/arts_change_conf.png" style='display:block; margin: 0 auto;'  alt="arts plus change conference logo"/>
    </div>
    <div class='column'>
      <li> <a id="lab-rat" href="https://www.sas.rochester.edu/PerformingArts/arts-change/archive/2021.html">Presenter at the Arts Plus Change Conference at University of Rochester</a> </li>
    </div>
  </div>

  <h1>2020</h1>
  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/kindey_international.png" style='display:block; margin: 0 auto;'  alt="kidney international logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://www.kidney-international.org/article/S0085-2538(20)31264-3/fulltext">Deletion of the proton receptor OGR1 in mouse osteoclasts impairs metabolic acidosis-induced bone resorption</a> </li>
    </div>
  </div>

  <div class='row'>
    <div class='column'>
      <img src="/assets/icons/Urolithisis.png" style='display:block; margin: 0 auto;'  alt="Urolithiasis Journal logo"/>
    </div>
    <div class='column'>
      <li> <a href="https://link.springer.com/article/10.1007/s00240-020-01223-5">Kidney stone formation and the gut microbiome are altered by antibiotics in genetic hypercalciuric stone-forming rats</a> </li>
    </div>
  </div>



</div>
