import { Component, OnInit } from '@angular/core';
import {  PROJECTS, Stories_LIST, ArtistsBooks_LIST } from '../utils/collection_contents';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  MoreStoriesNames: string[] = PROJECTS.More;
  StoriesNames: string[] = Stories_LIST;
  ArtistsBkNames: string[] = ArtistsBooks_LIST;


  constructor() { }

  ngOnInit(): void {
  }
  openNav(): void {
    (document.getElementById('mySidenav') as HTMLElement).style.width = '100%';
  }

  closeNav(): void {
    (document.getElementById('mySidenav') as HTMLElement).style.width = '0px';
  }

}
