import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  current_year: number = ((new Date()).getFullYear());

  constructor() {
  }

  ngOnInit(): void {
  }

}
