import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {loadComponent} from '../utils/utils';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-art-day-single-page',
  templateUrl: './art-day-single-page.component.html',
  styleUrls: ['./art-day-single-page.component.css']
})
export class ArtDaySinglePageComponent implements OnInit {
  @ViewChild('artBox', { static: true, read: ViewContainerRef }) artBox!: ViewContainerRef;
  collectionName = '';
  title = '';
  fileName = '';
  isInMoreFolder = false;
  constructor(private route: ActivatedRoute, private router: Router, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.fileName = String(params.get('fileName'));
      this.collectionName = String(params.get('collection'));
    });
    this.route.url.subscribe(urlSegments => {
      if (urlSegments.length > 1) {
        this.isInMoreFolder = urlSegments[1].path === 'More';
      }
    });
    if (this.collectionName === 'null') {
      this.collectionName = 'Stories';
    }
    loadComponent(this.artBox, this.collectionName, this.fileName, this.componentFactoryResolver, this.isInMoreFolder);
  }

}
