

<div class='center_text wrapper art_body'>
    <div *ngIf="ext==ExtensionType.Image" id='{{fileName}}'>
      <img class='scaled-img' src='{{filePath}}' (click)='overlayOn()' loading='lazy'/>
    </div>
    <div *ngIf="ext==ExtensionType.Image"  class='overlay' [id]="fileName + '-overlay'" (click)='overlayOff()' (wheel)='onWheel($event)' loading='lazy'><img class='centered' src='{{filePath}}' width='auto' height='auto'/></div>
    <audio *ngIf="ext==ExtensionType.M4a" controls><source src="{{filePath}}" type="audio/x-m4a">Your browser does not support the audio element.</audio>
    <audio *ngIf="ext==ExtensionType.Wav" controls><source src="{{filePath}}" type="audio/wav">Your browser does not support the audio element.</audio>
    <audio *ngIf="ext==ExtensionType.Mp3" controls><source src="{{filePath}}" type="audio/mp3">Your browser does not support the audio element.</audio>
  <div *ngIf="ext==ExtensionType.Html" style="text-align: left; width: 70%; margin: auto;" innerHTML="{{myhtml}}"></div>
  <div *ngIf="ext==ExtensionType.Pdf" class="pdf-container">
    <a href="{{filePath}}"><img class='pdf-thumbnail' src="{{thumbnailPath}}"/></a>
    <div class="pdf-overlay">
      <img src="//ssl.gstatic.com/docs/doclist/images/mediatype/icon_3_pdf_x32.png" title="PDF">
    </div>
  </div>

  <div *ngIf="ext==ExtensionType.Video" class='the169container'>
        <iframe  [src]="filePath | safe"></iframe>
    </div>
    <p *ngIf="fileListError">Error: there are multiple art files for this day but no appropriate page override was found</p>
</div>
<br *ngIf="addLineBreaks">
<br *ngIf="addLineBreaks">
