import {ComponentFactoryResolver, Input, ViewContainerRef} from '@angular/core';
import { ArtDayWrapperComponent } from '../art-day-wrapper/art-day-wrapper.component';
import {YEARS, PROJECTS, Stories_LIST, ArtistsBooks_LIST, DrawingsAndPaintings_LIST} from './collection_contents';
import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {ArtDayBodyComponent} from '../art-day-pieces/art-day-body.component';
import {CollectionFooterComponent} from '../art-day-pieces/collection-footer.component';

export const COLLECTIONS: { [key: string]: string[]} = {
  Stories : [],
  ArtistsBooks: [],
  DrawingsAndPaintings: []
};
for (const item of Stories_LIST) {
  COLLECTIONS.Stories.push(item);
}
for (const item of ArtistsBooks_LIST) {
  COLLECTIONS.ArtistsBooks.push(item);
}
for (const item of DrawingsAndPaintings_LIST) {
  COLLECTIONS.DrawingsAndPaintings.push(item);
}

export enum ExtensionType {
    Image,
    Video,
    M4a,
    Wav,
    Mp3,
    Filler,
    Html,
    Pdf,
    Other
}

export function stringToExtensionType( ext: string ): ExtensionType {
    ext = ext.toLowerCase();
    if (['png', 'jpeg', 'jpg'].includes( ext )) {
        return ExtensionType.Image;
    } else if (['youtube'].includes( ext )) {
        return ExtensionType.Video;
    } else if (ext === 'm4a') {
        return ExtensionType.M4a;
    } else if (ext === 'wav') {
        return ExtensionType.Wav;
    } else if (ext === 'mp3') {
        return ExtensionType.Mp3;
    } else if (ext === 'naf') {
      return ExtensionType.Filler;
    } else if (ext === 'html') {
      return ExtensionType.Html;
    } else if (ext === 'pdf') {
      return  ExtensionType.Pdf;
    }
    console.log( 'unsupported file type ' + ext );
    return ExtensionType.Other;
}

// @ts-ignore
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  // tslint:disable-next-line:typedef
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export function getBodyWidth(): number {
    const n = window.innerWidth;
    if (n > 1365) {
        return 1100;
    } else if (n > 750) {
        return 0.8 * n;
    } else if (n > 600) {
        return 600;
    }
    return n;
}
function loadCollectionContents(collectionName: string): string[] {
  let coll = [''];
  if (collectionName === 'DrawingsAndPaintings'){
    coll = COLLECTIONS[collectionName];
  }
  else if (PROJECTS.hasOwnProperty(collectionName)){
    coll = PROJECTS[collectionName];
  }
  else if (YEARS.hasOwnProperty(collectionName)){
    coll = YEARS[collectionName];
  }
  return coll;
}

export function loadCollection(artBox: ViewContainerRef, collectionName: string,
                               componentFactoryResolver: ComponentFactoryResolver,
                               numItems: number, isProjectPage: boolean): void {
  const coll = loadCollectionContents(collectionName);
  console.log('coll name: ', collectionName);
  console.log('coll length: ', coll.length);

  const artNames = [];
  for (let i = 0; i < coll.length && (i < numItems || isProjectPage); i++) {
    artNames.push( `${coll[i]}` );
  }
  console.log('art names: ', artNames);

  for (const item of artNames) {
    if (item.split('.').length === 1){
      loadProject(artBox, collectionName, item, componentFactoryResolver);
    }
    else {
      loadComponent(artBox, collectionName, item, componentFactoryResolver );
    }
  }

  // draw footer if it is a project
  if (isProjectPage){
    drawLabel(true, artNames[0].split('_'), componentFactoryResolver, artBox);
  }
}

export function drawLabel(drawFooterLine: boolean,
                          projectNamePieces: string[],
                          componentFactoryResolver: ComponentFactoryResolver,
                          viewContainerRef: ViewContainerRef): void{
  const componentFactory = componentFactoryResolver.resolveComponentFactory(CollectionFooterComponent);
  const ref = viewContainerRef.createComponent<CollectionFooterComponent>(componentFactory);
  ref.instance.drawFooterLine = drawFooterLine;
  ref.instance.rawTitle = projectNamePieces[1];
  ref.instance.yearStr = projectNamePieces[2];
  if (projectNamePieces.length > 3) {
    ref.instance.publisher = projectNamePieces[3].replace(/-/g, ' ');
  }
  if (projectNamePieces.length > 4) {
    ref.instance.fourth = projectNamePieces[4].replace(/-/g, ' ');
  }

}

export function loadYearsCollection(artBox: ViewContainerRef,
                                    year: string,
                                    componentFactoryResolver: ComponentFactoryResolver,
                                    numItems: number): void {
  const coll = loadCollectionContents(year);
  console.log('coll name: ', year);
  console.log('coll length: ', coll.length);

  const artNames = [];
  for (let i = 0; i < coll.length && (i < numItems); i++) {
    artNames.push( `${coll[i]}` );
  }
  console.log('art names: ', artNames);

  for (const item of artNames) {
    const collectionName = item.split('\\')[1];
    let fileName = item.split('\\')[2];
    if (item.split('.').length === 2){
      loadProject(artBox, collectionName, fileName, componentFactoryResolver);
    }
    else {
      let isInMoreFolder = false;
      if (fileName === 'More'){
        fileName = item.split('\\')[3];
        isInMoreFolder = true;
      }
      loadComponent(artBox, collectionName, fileName, componentFactoryResolver, isInMoreFolder);
    }
  }
}


export function loadProject(artBox: ViewContainerRef,
                            collectionName: string,
                            projectName: string,
                            componentFactoryResolver: ComponentFactoryResolver): void {
  const coll = loadCollectionContents(projectName);
  console.log('coll name: ', collectionName);
  console.log('project name: ', projectName);
  console.log('project length: ', coll.length);

  const artNames = coll.map(item => item);
  console.log('art names: ', artNames);

  const projectNamePieces = projectName.split('_');

  // draw header
  drawLabel(false, projectNamePieces, componentFactoryResolver, artBox);

  for (const item of artNames) {
    loadProjectComponent( artBox, collectionName, projectName, item, componentFactoryResolver );
  }

  // draw footer
  drawLabel(true, projectNamePieces, componentFactoryResolver, artBox);

}

export function addToCollection(artBox: ViewContainerRef, collectionName: string,
                                componentFactoryResolver: ComponentFactoryResolver,
                                numItems: number, isProjectPage: boolean): void {
  const coll = loadCollectionContents(collectionName);
  const artNames = [];
  for (let i = numItems - 2; i < coll.length && i < numItems; i++) {
    const title = `${coll[i]}`.split('_')[1];
    if (PROJECTS.hasOwnProperty(title) && !PROJECTS.hasOwnProperty(collectionName)) {
      loadCollection(artBox, title, componentFactoryResolver, 10000, true);
      continue;
    }
    artNames.push( `${coll[i]}` );
  }
  const viewContainerRef = artBox;
  for (const item of artNames) {
    if (item.split('.').length === 1){
      loadProject(artBox, collectionName, item, componentFactoryResolver);
    }
    else {
      loadComponent(artBox, collectionName, item, componentFactoryResolver );
    }
  }

  if (numItems >= coll.length && numItems <= coll.length + 1){
    if (PROJECTS.hasOwnProperty(collectionName)) {
      drawLabel(!isProjectPage, coll[0].split('_'), componentFactoryResolver, viewContainerRef);
    }
  }
}

export function loadProjectComponent(viewContainerRef: ViewContainerRef,
                                     collectionName: string,
                                     projectName: string,
                                     fileName: string,
                                     componentFactoryResolver: ComponentFactoryResolver
                                      ): void {
  const componentFactory = componentFactoryResolver.resolveComponentFactory(ArtDayBodyComponent);
  const ref = viewContainerRef.createComponent<ArtDayBodyComponent>(componentFactory);
  const filenamePieces = fileName.split('_');
  ref.instance.filePath = '/assets/' + collectionName + '/' + projectName + '/' + fileName;
  ref.instance.extStr = filenamePieces[filenamePieces.length - 1].split('.')[1];
  ref.instance.addLineBreaks = true;
}

export function loadComponent(viewContainerRef: ViewContainerRef, collectionName: string, fileName: string,
                              componentFactoryResolver: ComponentFactoryResolver, isInMoreFolder: boolean = false): void {
  if (PROJECTS.hasOwnProperty(collectionName)){
      const componentFactory = componentFactoryResolver.resolveComponentFactory(ArtDayBodyComponent);
      const ref = viewContainerRef.createComponent<ArtDayBodyComponent>(componentFactory);
      const filenamePieces = fileName.split('_');
      ref.instance.filePath = '/assets/' + collectionName + '/' + fileName;
      ref.instance.extStr = filenamePieces[filenamePieces.length - 1].split('.')[1];
      ref.instance.addLineBreaks = true;
    }
    else{
      const filenamePieces = fileName.split('.')[0].split('_');
      const componentFactory = componentFactoryResolver.resolveComponentFactory(ArtDayWrapperComponent);
      const ref = viewContainerRef.createComponent<ArtDayWrapperComponent>(componentFactory);
      ref.instance.filePath = '/assets/' + collectionName + '/' + fileName;
      ref.instance.title = filenamePieces[1];
      ref.instance.year = filenamePieces[2];
      if (filenamePieces.length > 3) {
        ref.instance.publisher = filenamePieces[3].replace(/-/g, ' ');
      }
      if (filenamePieces.length > 4) {
        ref.instance.fourth = filenamePieces[4].replace(/-/g, ' ');
      }
      ref.instance.ext = fileName.split('.')[fileName.split('.').length - 1];
      if (isInMoreFolder) {
        ref.instance.filePath = '/assets/Stories/More/' + fileName;
      }
      if (stringToExtensionType(ref.instance.ext) === ExtensionType.Html) {
        fetch(ref.instance.filePath)
        .then(response => response.text())
        .then(htmlContent => {
          ref.instance.myhtml = htmlContent;
        })
        .catch(error => {
          console.error('Error fetching HTML:', error);
        });
        }
    }
}
